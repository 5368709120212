<template>
  <v-alert
    type="error"
    dense
  >
    <strong>{{pathCrumb}}:</strong> {{messages.join(' ')}}
  </v-alert>
</template>

<script>
import {pathToTitlePath} from '../../lib/genericHelpers';

export default ({
  props: {
    path: String,
    messages: Array,
  },

  computed: {
    pathCrumb: function() {
      return pathToTitlePath(this.path);
    }
  }
})
</script>
