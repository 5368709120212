<template>
  <v-form ref="form">
    <v-card flat>
      <v-card-title class="text-left">{{ title }}</v-card-title>
      <v-card-text class="text-left">
        <Field
          v-for="(field, i) in fields"
          :key="`field-${i}`"
          :field="field"
          :parent="path"
        >
        </Field>
        <FormAlert
          v-for="(formError, i) in Object.keys(formErrors)"
          :key="`form-alert-${i}`"
          v-bind:path="formError"
          v-bind:messages="formErrors[formError]"
        >
        </FormAlert>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          color="#ff002d"
          class="white--text"
          :disabled="formIsLocked"
          @click="encode(type)"
        >
          Encode
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
      >
        <ConfigurationResult
          :key="`result-${type}`"
          v-bind:type="type"
          v-on:close-dialog="dialog=false"
          v-on:open-push-dialog="push=true">
        </ConfigurationResult>
      </v-dialog>
      <v-dialog
        v-model="push"
        persistent
        max-width="400px"
      >
        <ConfirmPush
          :key="`push-${type}`"
          v-bind:type="type"
          v-on:close-push-dialog="push=false">
        </ConfirmPush>
      </v-dialog>
      <v-dialog
        v-model="alert"
        persistent
        max-width="800px"
      >
        <ConfigurationErrorResult
          :key="`error-result-${type}`"
          v-bind:response="errorResponse"
          v-on:close-dialog="alert=false">
        </ConfigurationErrorResult>
      </v-dialog>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios';
import Field from './Field';
import FormAlert from './FormAlert';
import ConfigurationResult from './ConfigurationResult';
import ConfirmPush from './ConfirmPush';
import ConfigurationErrorResult from './ConfigurationErrorResult';

export default ({
  name: 'ConfigurationForm',

  components: {
    Field,
    ConfigurationResult,
    ConfirmPush,
    ConfigurationErrorResult,
    FormAlert
  },

  data: () => ({
    dialog: false,
    alert: false,
    push: false,
    errorResponse: null
  }),

  props: {
    title: String,
    fields: Array,
    type: String,
  },

  computed: {
    path: function() {
      return this.type
    },
    formIsLocked: function() {
      return this.$store.getters.getFormIsLocked(this.type);
    },
    apiEndpoint: function() {
      return `/api${this.$store.state.api.root}`;
    },
    formErrors: function() {
      return this.$store.getters.getFormErrors(this.type);
    },
  },

  created: function() {
    // Init Vuex data storage for this configurations' form data.
    this.$store.commit('updateConfigurationValue', { [this.path]: {} });
  },

  methods: {
    encode: async function(configuration_type) {
      // Collect the form data in a way we can submit this to the API.
      const formData = this.$store.getters.getFormData(configuration_type);

      let response = {data: {}};
      try {
        // Submit the form data to the API and collect the response.
        response = await axios.post(`${(process.env.VUE_APP_API_HOST || '')}${(process.env.VUE_APP_APP_PATH || '')}${this.apiEndpoint}/${configuration_type}/encode`, formData);

        // store the encoded data to the current state.
        this.$store.commit('updateConfigurationResult', { [this.type]: response.data });

        this.dialog = true;
      } catch(e) {
        response = e.response;
        this.errorResponse = response;
        this.alert = true;
      }
    }
  }
})
</script>
