<template>
  <v-card flat>
    <v-card-title class="text-left">Encoded</v-card-title>
    <v-card-text class="text-left">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-textarea label="JSON" v-bind:value="json"></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Hex array" v-bind:value="hex"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Hex string"
              v-bind:value="hex_byte_only"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Base64" v-bind:value="base64"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="crc">
            <v-text-field label="CRC" v-bind:value="crc"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="tag">
            <v-text-field label="Tag" v-bind:value="tag"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="fPort" v-bind:value="fPort"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
      <v-btn color="blue darken-1" text v-if="canPush" @click="push">
        Push
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ConfigurationResult',

    components: {},

    props: {
      type: String,
    },

    computed: {
      encodedData() {
        return this.$store.state.encodedData[this.type];
      },
      json() {
        if (this.encodedData.json !== null) {
          return JSON.stringify(JSON.parse(this.encodedData.json), null, 2);
        }

        return '';
      },
      hex() {
        return this.encodedData.hex;
      },
      hex_byte_only() {
        return this.encodedData.hex_byte_only;
      },
      base64() {
        return this.encodedData.base64;
      },
      crc() {
        return this.encodedData.crc;
      },
      tag() {
        return this.encodedData.tag;
      },
      canPush() {
        return this.$store.getters.getPushConfiguration() !== false;
      },
      fPort() {
        return this.encodedData.fPort;
      },
    },

    methods: {
      close() {
        this.$emit('close-dialog');
      },
      push() {
        this.$emit('open-push-dialog');
      },
    },
  };
</script>
