<template>
  <v-text-field
    ref="textField"
    v-model="value"
    v-bind:label="label"
    v-bind:rules="[rules.required, rules.hex, rules.range]"
    type="text"
    v-bind:hint="hintText"
    persistent-hint
    v-bind:loading="haveRange"
    v-bind:disabled="fieldIsLocked"
    v-bind:prepend-icon="fieldIcon"
    v-bind:suffix="unit"
    @keyup="updateFieldErrors"
    @change="updateFieldErrors"
  >
    <template v-slot:progress>
      <v-progress-linear
        v-if="haveRange"
        v-bind:value="progressValue"
        v-bind:color="progressColor"
        absolute
        height="2"
      ></v-progress-linear>
    </template>
  </v-text-field>
</template>

<script>
import rules from '../../lib/validationRules';

export default ({
  name: 'InputHex',

  props: ['defaultValue', 'label', 'options', 'path', 'readOnly', 'unit'],

  data: function() {
    return {
      valueProxy: this.defaultValue.startsWith('0x') ? this.defaultValue : '0x' + this.defaultValue,
      isMounted: false,
      clipDelay: 10000,
      clipDataTimer: null,
      updatesPerSecond: 2,
      rules: {
        required: (value) => rules.required(value),
        hex: (value) => rules.hex(value),
        range: (value) => {
          const numericValue = value.startsWith('0x') ? parseInt(value.slice(2), 16) : parseInt(value, 16);
          return rules.range(this.min, this.max, numericValue, this.hintText);
        }
      }
    }
  },

  computed: {
    value: {
      set: function(value) {
        this.valueProxy = value;
        this.updateConfigurationValue(value);
      },
      get: function() {
        return this.valueProxy;
      }
    },
    min: function() {
      return typeof (this.options || {}).ge_hex === 'string' ? parseInt(this.options.ge_hex.slice(2), 16) : null;
    },
    max: function() {
      return typeof (this.options || {}).le_hex === 'string' ? parseInt(this.options.le_hex.slice(2), 16) : null;
    },
    haveRange: function() {
      if (typeof this.min !== 'number' && typeof this.max !== 'number') return false;
      if (this.min === this.max) return false;
      return true;
    },
    underMin: function() {
      return (this.min !== null && this.value < this.min);
    },
    overMax: function() {
      return (this.max !== null && this.value > this.max);
    },
    progressValue: function() {
      if (this.underMin || this.overMax) return 100;

      if (this.max !== null) {
        return Math.min((this.valueProxy/this.max) * 100);
      }
      return 0
    },
    progressColor: function() {
      if (this.underMin || this.overMax) return "error";
      return "success"
    },
    hintText: function() {
      if (!this.haveRange) return '';
      // Convert the minimum and maximum range values to hexadecimal values.
      let minLabel = this.min.toString(16).toUpperCase();
      let maxLabel = this.max.toString(16).toUpperCase();

      // Add leading zeros to minLabel to match length of maxLabel.
      minLabel = minLabel.padStart(maxLabel.length, '0');
      
      // Add the 0x prefix to the labels
      minLabel = '0x' + minLabel;
      maxLabel = '0x' + maxLabel;

      if (typeof this.min === 'number' && typeof this.max === 'number') {
        return `${minLabel} Up to and including ${maxLabel}.`;
      }
      if (typeof this.min === 'number') {
        return `With a minimum of ${minLabel}.`;
      }
      if (typeof this.max === 'number') {
        return `With a maximum of ${maxLabel}.`;
      }

      return '';
    },
    fieldIsLocked: function() {
      return this.readOnly || (typeof this.min === 'number' && this.min === this.max);
    },
    fieldIcon: function() {
      const iconOk = 'mdi-check';
      const iconError = 'mdi-alert';

      if (!this.isMounted) return iconOk;

      return this.$refs.textField.hasError ? iconError : iconOk;
    }
  },

  methods: {
    updateConfigurationValue: function(value) {
      this.$store.commit('updateConfigurationValue', { [this.path]: value });
    },

    updateFieldErrors: function() {
      // Delete this field from the list of errored fields.
      this.$store.commit('deleteErrorField', this.path);

      if (this.$refs.textField.hasError) {
        // Add this field to the list of errored fields.
        this.$store.commit('addErrorField', [this.path, this.$refs.textField.messagesToDisplay]);
      }
    }
  },

  mounted: function() {
    this.updateConfigurationValue(this.valueProxy);
    this.isMounted = true;
  }
})
</script>
