<template>
  <VueMarkdown
    v-bind:source="fieldDescription"
    v-bind:plugins="plugins"
  ></VueMarkdown>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown';
import katex from '@iktakahiro/markdown-it-katex';

export default ({
  name: 'FieldDescription',

  props: {
    fieldDescription: String
  },

  components: {
    VueMarkdown
  },

  data: function() {
    return {
      plugins: [{
        plugin: katex,
        options: { throwOnError: false, errorColor: ' #cc0000' }
      }]
    }
  }
})
</script>
