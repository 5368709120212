<template>
  <v-container v-if="field.fields && field.fields.length">
    <v-row v-if="field.fields && field.fields.length">
      <v-col cols="12" v-if="field.type && field.type.name === 'dict'">
        <v-card>
          <v-card-title>
            {{ label }}
          </v-card-title>
          <v-card-subtitle v-if="fieldDescription">
            <FieldDescription v-bind:fieldDescription="fieldDescription"></FieldDescription>
          </v-card-subtitle>
          <v-card-text class="text-left">
            <field
              v-for="(childField, i) in field.fields"
              :key="i"
              v-bind:field="childField"
              v-bind:parent="path"
            >
            </field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="field.type && field.type.name === 'list'">
        <v-card>
          <v-card-title>
            {{ label }}
          </v-card-title>
          <v-card-subtitle v-if="fieldDescription">
            <FieldDescription v-bind:fieldDescription="fieldDescription"></FieldDescription>
          </v-card-subtitle>
          <v-card-text class="text-left">
            <field
              v-for="(childField, i) in fieldTypeListChildrenFactory"
              :key="i"
              v-bind:field="childField"
              v-bind:parent="path"
            >
            </field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-row>
      <v-col cols="6" v-if="field.type && field.type.name === 'str'">
        <InputText
          v-bind:defaultValue="value"
          v-bind:label="label"
          v-bind:path="path"
          v-bind:readOnly="readOnly"
          v-bind:unit="unit"
        ></InputText>
      </v-col>
      <v-col cols="6" v-if="field.type && field.type.name === 'int'">
        <InputInt
          v-bind:defaultValue="value"
          v-bind:label="label"
          v-bind:options="field.type.args"
          v-bind:path="path"
          v-bind:readOnly="readOnly"
          v-bind:unit="unit"
        ></InputInt>
      </v-col>
      <v-col cols="6" v-if="field.type && field.type.name === 'float'">
        <InputFloat
          v-bind:defaultValue="value"
          v-bind:label="label"
          v-bind:options="field.type.args"
          v-bind:path="path"
          v-bind:readOnly="readOnly"
          v-bind:unit="unit"
        ></InputFloat>
      </v-col>
      <v-col cols="6" v-if="field.type && field.type.name === 'bool'">
        <InputBool
          v-bind:defaultValue="value"
          v-bind:label="label"
          v-bind:path="path"
          v-bind:readOnly="readOnly"
        ></InputBool>
      </v-col>
      <v-col cols="6" v-if="field.type && field.type.name === 'enum'">
        <InputEnum
          v-bind:defaultValue="value"
          v-bind:label="label"
          v-bind:options="field.type.args.enum"
          v-bind:path="path"
          v-bind:readOnly="readOnly"
        ></InputEnum>
      </v-col>
      <v-col cols="6" v-if="field.type && field.type.name === 'tag'">
        <InputHex
          v-bind:defaultValue="currentTimestampHex"
          v-bind:label="label"
          v-bind:options="field.type.args"
          v-bind:path="path"
          v-bind:readOnly="readOnly"
          v-bind:unit="unit"
        ></InputHex>
      </v-col>
      <v-col cols="6" v-if="field.type && field.type.name === 'hex'">
        <InputHex
          v-bind:defaultValue="value"
          v-bind:label="label"
          v-bind:options="field.type.args"
          v-bind:path="path"
          v-bind:readOnly="readOnly"
          v-bind:unit="unit"
        ></InputHex>
      </v-col>
      <v-col cols="6">
        <v-sheet
          v-if="fieldDescription"
          rounded
          color="#f5f5f5"
          class="field-description"
        >
          <FieldDescription v-bind:fieldDescription="fieldDescription"></FieldDescription>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InputText from './InputText';
import InputInt from './InputInt';
import InputFloat from './InputFloat';
import InputBool from './InputBool';
import InputEnum from './InputEnum';
import FieldDescription from './FieldDescription';
import {snakeCaseToTitle} from '../../lib/genericHelpers';
import InputHex from './InputHex.vue';

export default ({
  name: 'Field',

  components: {
    InputText,
    InputInt,
    InputFloat,
    InputBool,
    InputEnum,
    FieldDescription,
    InputHex
},

  props: {
    field: Object,
    parent: String,
  },

  computed: {
    path: function() {
      const key = [this.field.key, (this.field.type.name === 'list' ? '[]' : '' )].join('');
      return [this.parent, key].join('.');
    },

    value: function() {
      return this.$store.getters.getFieldDefaultValue(this.path, this.field.default);
    },

    label: function() {
      return snakeCaseToTitle(this.field.key);
    },

    fieldTypeListChildrenFactory: function() {
      // This computed property is only for field with type.name === 'list'.
      if (this.field.type.name !== 'list') return [];

      // Make sure there is a child field to use as template.
      if (!this.field.fields || this.field.fields.length < 1) return [];

      // Point the template field.
      const templateField = this.field.fields[0];

      // Copy field template for each entry in the default value.
      const childFields = this.field.default.map((defaultValue, index) => {
        return Object.assign(
          {},                     // Create new clean object.
          templateField,          // Start with template field.
          {                       // Override child specific values.
            key: String(index+1),
            default: defaultValue
          }
        );
      });

      return childFields;
    },

    readOnly: function() {
      return (this.field.meta || {}).read_only === true;
    },

    unit: function() {
      return (this.field.meta || {}).unit || '';
    },

    fieldDescription: function() {
      if (typeof (this.field.meta || {}).description !== 'string' || this.field.meta.description === '') {
        return '';
      }

      return this.field.meta.description;
    },

    currentTimestampHex: function() {
      // Get the current timestamp in seconds and convert it to a hexadecimal string.
      const timestamp = Math.floor(Date.now() / 1000);
      return '0x' + timestamp.toString(16).toUpperCase();
    }
  }
})
</script>

<style scoped>
.field-description {
  padding: 15px;
}

.v-application .field-description p:last-child {
  padding-bottom: 0;
}
</style>