<template>
  <v-text-field
    ref="textField"
    v-model="value"
    v-bind:label="label"
    v-bind:rules="[rules.required]"
    v-bind:disabled="fieldIsLocked"
    v-bind:prepend-icon="fieldIcon"
    v-bind:suffix="unit"
    @keyup="updateFieldErrors"
    @change="updateFieldErrors"
  ></v-text-field>
</template>

<script>
import rules from '../../lib/validationRules';

export default ({
  name: 'InputText',

  components: {},

  props: ['defaultValue', 'label', 'path', 'readOnly', 'unit'],

  data: function() {
    return {
      valueProxy: this.defaultValue,
      isMounted: false,
      rules: {
        required: (value) => rules.required(value)
      }
    }
  },

  computed: {
    fieldIsLocked: function() {
      return this.readOnly;
    },
    value: {
      set: function(value) {
        this.valueProxy = value;
        this.updateConfigurationValue(value);
      },
      get: function() {
        return this.defaultValue;
      }
    },
    fieldIcon: function() {
      const iconOk = 'mdi-check';
      const iconError = 'mdi-alert';

      if (!this.isMounted) return iconOk;

      return this.$refs.textField.hasError ? iconError : iconOk;
    }
  },

  methods: {
    updateConfigurationValue: function(value) {
      this.$store.commit('updateConfigurationValue', { [this.path]: value });
    },

    updateFieldErrors: function() {
      // Delete this field from the list of errored fields.
      this.$store.commit('deleteErrorField', this.path);

      if (this.$refs.textField.hasError) {
        // Add this field to the list of errored fields.
        this.$store.commit('addErrorField', [this.path, this.$refs.textField.messagesToDisplay]);
      }
    }
  },

  mounted: function() {
    this.updateConfigurationValue(this.value);
    this.isMounted = true;
  }
})
</script>
