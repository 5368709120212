<template>
  <v-text-field
    ref="textField"
    v-model="value"
    v-bind:label="label"
    v-bind:rules="[rules.required, rules.int, rules.range]"
    type="number"
    v-bind:hint="hintText"
    persistent-hint
    v-bind:loading="haveRange"
    v-bind:disabled="fieldIsLocked"
    v-bind:prepend-icon="fieldIcon"
    v-bind:suffix="unit"
    @keyup="updateFieldErrors"
    @change="updateFieldErrors"
  >
    <template v-slot:progress>
      <v-progress-linear
        v-if="haveRange"
        v-bind:value="progressValue"
        v-bind:color="progressColor"
        absolute
        height="2"
      ></v-progress-linear>
    </template>
  </v-text-field>
</template>

<script>
import rules from '../../lib/validationRules';

export default ({
  name: 'InputInt',

  components: {},

  props: ['defaultValue', 'label', 'options', 'path', 'readOnly', 'unit'],

  data: function() {
    return {
      valueProxy: this.defaultValue,
      isMounted: false,
      clipDelay: 10000,
      clipDataTimer: null,
      updatesPerSecond: 2,
      rules: {
        required: (value) => rules.required(value),
        int: (value) => rules.int(value),
        range: (value) => rules.range(this.min, this.max, value, this.hintText)
      }
    }
  },

  computed: {
    value: {
      set: function(value) {
        this.valueProxy = value;
        this.updateConfigurationValue(value);
      },
      get: function() {
        return this.valueProxy;
      }
    },
    min: function() {
      return typeof (this.options || {}).ge === 'number' ? this.options.ge : null;
    },
    max: function() {
      return typeof (this.options || {}).le === 'number' ? this.options.le : null;
    },
    haveRange: function() {
      if (typeof this.min !== 'number' && typeof this.max !== 'number') return false;
      if (this.min === this.max) return false;
      return true;
    },
    underMin: function() {
      return (this.min !== null && this.value < this.min);
    },
    overMax: function() {
      return (this.max !== null && this.value > this.max);
    },
    progressValue: function() {
      if (this.underMin || this.overMax) return 100;

      if (this.max !== null) {
        return Math.min((this.value/this.max) * 100);
      }

      return 0
    },
    progressColor: function() {
      if (this.underMin || this.overMax) return "error";
      return "success"
    },
    hintText: function() {
      if (!this.haveRange) return '';
      if (typeof this.min === 'number' && typeof this.max === 'number') {
        return `${this.min} Up to and including ${this.max}.`;
      }
      if (typeof this.min === 'number') {
        return `With a minimum of ${this.min}.`;
      }
      if (typeof this.max === 'number') {
        return `With a maximum of ${this.max}.`;
      }

      return '';
    },
    fieldIsLocked: function() {
      return this.readOnly || (typeof this.min === 'number' && this.min === this.max);
    },
    fieldIcon: function() {
      const iconOk = 'mdi-check';
      const iconError = 'mdi-alert';

      if (!this.isMounted) return iconOk;

      return this.$refs.textField.hasError ? iconError : iconOk;
    }
  },

  methods: {
    updateConfigurationValue: function(value) {
      this.$store.commit('updateConfigurationValue', { [this.path]: value });
    },

    updateFieldErrors: function() {
      // Delete this field from the list of errored fields.
      this.$store.commit('deleteErrorField', this.path);

      if (this.$refs.textField.hasError) {
        // Add this field to the list of errored fields.
        this.$store.commit('addErrorField', [this.path, this.$refs.textField.messagesToDisplay]);
      }
    }
  },

  mounted: function() {
    this.updateConfigurationValue(this.value);
    this.isMounted = true;
  }
})
</script>
