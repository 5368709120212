export default {
  required (value, message='Please insert a value.') {
    return /[^\s]+/.test(value) ? true : message;
  },
  int (value, message='Please insert a valid number.') {
    return value !== '' && !isNaN(value) && value % 1 === 0 ? true : message;
  },
  float (value, message='Please insert a valid number.') {
    return value !== '' && !isNaN(value) ? true : message;
  },
  range (min, max, value, message='Please enter a value matching the range.') {
    if ((min !== null && min > value) ||
        (max !== null && max < value)) {
      return message;
    }

    return true;
  },
  hex: (value, message='Must be a valid hexadecimal string, example: 0x12AB') => {
    const re = /^0x[0-9A-F]{2,}$/i;
    return re.test(value) || message;
  }
};