<template>
  <v-select
    :items="options"
    :label="label"
    v-model="value"
    v-bind:disabled="fieldIsLocked"
  >
  </v-select>
</template>

<script>
export default ({
  name: 'InputRadio',

  components: {},

  props: ['options', 'defaultValue', 'label', 'path', 'readOnly'],

  data: function() {
    return {
      valueProxy: this.defaultValue
    }
  },

  computed: {
    fieldIsLocked: function() {
      return this.readOnly || (this.options || []).length < 2;
    },
    value: {
      set: function(value) {
        this.valueProxy = value;
        this.updateConfigurationValue(value);
      },
      get: function() {
        return this.defaultValue;
      }
    }
  },

  methods: {
    updateConfigurationValue: function(value) {
      this.$store.commit('updateConfigurationValue', { [this.path]: value });
    }
  },

  mounted: function() {
    this.updateConfigurationValue(this.value);
  }
})
</script>
