<template>
  <v-card class="mx-auto my-12" max-width="420">
    <v-card-title>Open a NEON Configuration Generator</v-card-title>
    <v-divider class="mx-4 my-4"></v-divider>
    <v-card-text>
      <v-row align="center" class="mx-0">
        <v-select
          v-model="selectedSensorType"
          v-bind:items="sensorTypes"
          v-on:change="onChangeSensorType"
          item-text="text"
          item-value="value"
          label="Select a sensor type"
          return-object
        ></v-select>
        <v-select
          v-model="selectedProtocolVersion"
          v-bind:items="protocolVersions"
          v-bind:disabled="protocolDisabled"
          label="Select a protocol version"
        ></v-select>
        <div v-if="activeErrorMessage">
          <v-alert type="error" color="#cc0000" dense>
            {{ activeErrorMessage }}
          </v-alert>
        </div>
      </v-row>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
      <v-btn
        block
        color="#ff002d"
        class="white--text"
        v-bind:disabled="submitDisabled"
        v-on:click="onOpen"
      >
        Open
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'DashboardPage',

    data: () => ({
      sensorTypes: [],
      selectedSensorType: {
        text: null,
        value: null,
      },
      protocolVersions: [],
      selectedProtocolVersion: null,
      activeErrorMessage: null,
      errorMessages: {},
    }),

    computed: {
      protocolDisabled: function () {
        return (
          this.selectedSensorType.value === null ||
          this.protocolVersions.length < 1
        );
      },
      submitDisabled: function () {
        return (
          this.selectedSensorType.value === null ||
          this.selectedProtocolVersion === null
        );
      },
    },

    methods: {
      onChangeSensorType: function (e) {
        // Get the protocol versions for the current device type.
        // Automatically select the protocol version if there is only one.
        this.activeErrorMessage = null;
        this.updateSelectedDeviceProtocolVersions(e);

        // Update the query parameters with the selected device type.
        this.updateURLPath();
      },

      onOpen: function () {
        const configGeneratorPath = [
          '/neon',
          this.selectedSensorType.value,
          this.selectedProtocolVersion,
        ].join('/');
        window.open(configGeneratorPath);
      },

      updateURLPath: function () {
        // Replace the current URL with the updated query parameters
        window.history.pushState(
          {},
          '',
          `/neon/${this.selectedSensorType.value}`
        );
      },

      updateSelectedDeviceProtocolVersions: function (e) {
        this.selectedProtocolVersion = null;
        this.protocolVersions = e.protocolVersions;

        // Auto-select the single option if there's just one.
        if (this.protocolVersions.length < 2) {
          this.selectedProtocolVersion = this.protocolVersions[0].value;
        }
      },

      getDeviceTypeByURLPath: function () {
        // Filter the default selected device type based the `device_type` value in the query params.
        const urlPath = window.location.pathname.split('/');
        const filteredUrlPath = urlPath.filter((path) => path !== '');

        // Check if the path is in the "/neon/<device_type>" format.
        if (
          filteredUrlPath.length < 2 ||
          filteredUrlPath[0] !== 'neon' ||
          filteredUrlPath[1] === ''
        ) {
          return;
        }

        return filteredUrlPath[1];
      },

      setDeviceTypeByPath: function () {
        // Get the device type using the current URL path.
        const deviceType = this.getDeviceTypeByURLPath();

        // Search the matching sensor type in the list of known devices.
        const sensorType = this.sensorTypes.filter(
          (sensorType) => sensorType.value == deviceType.toLowerCase()
        );

        // Set the first found value as the selected value.
        if (sensorType.length > 0) {
          const selectedSensorType = sensorType[0];
          this.selectedSensorType.value = selectedSensorType.value;
          this.selectedSensorType.text = selectedSensorType.text;

          // Get the list of protocol versions and auto select if there is only one available.
          // Get the protocol versions for the current device type.
          this.updateSelectedDeviceProtocolVersions(selectedSensorType);

          // Update the query parameters with the selected device type.
          this.updateURLPath();
        } else {
          this.activeErrorMessage = this.errorMessages.sensorTypeNotFound;
        }
      },
    },

    created: async function () {
      this.errorMessages = {
        sensorTypeNotFound:
          'Sensor type not found, please select a sensor type using the form above.',
      };

      // Supported sensor types and protocol versions.
      this.sensorTypes = [
        {
          text: 'NEON Contact Sensor',
          value: 'cs',
          protocolVersions: [
            {
              text: 'Protocol v1',
              value: 'v1',
            },
          ],
        },
        {
          text: 'NEON Multi Turn Valve Sensor',
          value: 'mt',
          protocolVersions: [
            {
              text: 'Protocol v2',
              value: 'v2',
            },
          ],
        },
        {
          text: 'NEON Pressure Gauge',
          value: 'pg',
          protocolVersions: [
            {
              text: 'Protocol v1',
              value: 'v1',
            },
          ],
        },
        {
          text: 'NEON Pressure Sensor',
          value: 'ps',
          protocolVersions: [
            {
              text: 'Protocol v1',
              value: 'v1',
            },
          ],
        },
        {
          text: 'NEON Quarter Turn Valve Sensor',
          value: 'qt',
          protocolVersions: [
            {
              text: 'Protocol v2',
              value: 'v2',
            },
          ],
        },
        {
          text: 'NEON Temperature Sensor',
          value: 'ts',
          protocolVersions: [
            {
              text: 'Protocol v2',
              value: 'v2',
            },
          ],
        },
        {
          text: 'NEON Temperature Transmitter',
          value: 'tt',
          protocolVersions: [
            {
              text: 'Protocol v2',
              value: 'v2',
            },
            {
              text: 'Protocol v3',
              value: 'v3',
            },
            {
              text: 'Protocol v4',
              value: 'v4',
            },
          ],
        },
        {
          text: 'NEON Vibration Sensor',
          value: 'vb',
          protocolVersions: [
            {
              text: 'Protocol v2',
              value: 'v2',
            },
            {
              text: 'Protocol v3',
              value: 'v3',
            },
            {
              text: 'Protocol v4',
              value: 'v4',
            },
          ],
        },
      ];

      // Check the query parameters to set the initial device type.
      this.setDeviceTypeByPath();
    },
  };
</script>
