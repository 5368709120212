<template>
  <v-select
    :items="options"
    item-text="label"
    item-value="value"
    :label="label"
    v-model="value"
    v-bind:disabled="fieldIsLocked"
  >
  </v-select>
</template>



<script>
export default ({
  name: 'InputInt',

  components: {},

  props: ['defaultValue', 'label', 'path', 'readOnly'],

  data: function() {
    return {
      valueProxy: this.defaultValue,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ]
    }
  },

  computed: {
    fieldIsLocked: function() {
      return this.readOnly;
    },
    value: {
      set: function(value) {
        const boolValue = [true, 1, "1", 'y', 'Y', 'yes', 'Yes', 'YES'].includes(value);
        this.valueProxy = boolValue;
        this.updateConfigurationValue(boolValue);
      },
      get: function() {
        return this.valueProxy;
      }
    }
  },

  methods: {
    updateConfigurationValue: function(value) {
      this.$store.commit('updateConfigurationValue', { [this.path]: value });
    }
  },

  mounted: function() {
    this.updateConfigurationValue(this.value);
  }
})
</script>
