export const snakeCaseToTitle = (value) => {
    return value.split('_').map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(' ');
}

export const pathToTitlePath = (value) => {
    const pathSteps = value.split('.');
    if (pathSteps.length < 2) return snakeCaseToTitle(value);
    return pathSteps.slice(1).map(x => x.replace('[]', '')).map(snakeCaseToTitle).join(' / ')
}

export default {
    snakeCaseToTitle,
    pathToTitlePath
}