<template>
  <v-card flat>
    <v-card-title class="text-left">{{ title }}</v-card-title>
    <v-card-text class="text-left">
      <VueMarkdown
        v-bind:source="source"
        v-bind:plugins="plugins"
        v-bind:anchorAttributes="anchorAttributes"
      ></VueMarkdown>
    </v-card-text>
  </v-card>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown';
import katex from '@iktakahiro/markdown-it-katex';

export default ({
  name: 'MarkdownContent',

  props: {
    title: String,
    source: String
  },

  components: {
    VueMarkdown
  },

  data: function() {
    return {
      plugins: [{
        plugin: katex,
        options: { throwOnError: false, errorColor: ' #cc0000' }
      }],
      anchorAttributes: {
        target: "_blank"
      }
    }
  }
})
</script>
