<template>
  <v-app>
    <v-app-bar
      app
      color="#ff002d"
      dark
      flat
    >
      <div class="d-flex align-center">
        <v-img
          alt="TWTG Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="40"
        />
      </div>

      <v-spacer></v-spacer>

      <template v-if="productDetail && productDetail.url && productDetail.label">
        <v-btn
          v-bind:href="productDetail.url"
          target="_blank"
          text
        >
          <span class="mr-2">{{productDetail.label}}</span>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <DashboardPage v-if="appType === 'dashboard'"/>
      <FormPage v-if="appType === 'configurator'"/>
    </v-main>
  </v-app>
</template>

<script>
import DashboardPage from './components/pages/DashboardPage';
import FormPage from './components/pages/FormPage';

export default {
  name: 'ConfigurationGenerator',

  components: {
    DashboardPage,
    FormPage,
  },

  computed: {
    productDetail: function() {
      return this.$store.state.productDetail;
    },

    appType: function() {
      if (typeof process.env.VUE_APP_TYPE !== 'string') return 'configurator';
      return process.env.VUE_APP_TYPE.toLowerCase();
    }
  }
};
</script>
