<template>
  <v-card flat :loading="loading">
    <v-card-title class="text-left">Confirmation</v-card-title>
    <v-card-text class="text-left">
      <p>You are about to push the configuration trough.</p>
      <p>Do you want to continue?</p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="blue darken-1"
        text
        @click="push"
      >
        Continue
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="close"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default ({
  name: 'ConfirmPush',

  components: {
  },

  data: () => ({
    loading: false,
  }),

  props: {
    type: String
  },

  computed: {
    formData () {
      return JSON.stringify(this.$store.state.formData[this.type]);
    },
    encodedData () {
      return this.$store.state.encodedData[this.type];
    },
    json() {
      return this.encodedData.json
    },
    hex () {
      return this.encodedData.hex;
    },
    hex_byte_only () {
      return this.encodedData.hex_byte_only;
    },
    base64 () {
      return this.encodedData.base64;
    },
    crc () {
      return this.encodedData.crc;
    }
  },

  methods: {
    close () {
      this.loading = false;
      this.$emit('close-push-dialog');
    },
    async push () {
      this.loading = true;

      const {url, meta} = this.$store.getters.getPushConfiguration();
      const pushData = {
        meta: Object.assign({}, meta, {
          form: this.formData,
        }),
        data: {
          type: this.type,
          json: this.json,
          hex: this.hex,
          hexByteOnly: this.hex_byte_only,
          base64: this.base64,
          crc: this.crc
        }
      };

      try {
        // Submit the form data to the API and collect the response.
        await axios.post(url, pushData);
        this.close();
      } catch(e) {
        console.error(e);
        this.close();
      }
    }
  }
})
</script>
