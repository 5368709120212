<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card elevation="0" flat>
          <v-card-title class="text-left">Configuration Generator</v-card-title>
          <v-card-subtitle class="text-left" v-show="!activeErrorMessage">{{
            appTitle
          }}</v-card-subtitle>
          <v-card-text class="text-left">
            <div v-if="activeErrorMessage">
              <v-alert text color="#cc0000" type="error">
                <div class="text-left">
                  {{ activeErrorMessage }}
                </div>
                <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

                <v-row align="center" no-gutters>
                  <v-col class="text-left">
                    Please navigate back to the home dashboard and select a
                    supported sensor type and protocol version.
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="shrink">
                    <v-btn
                      color="info"
                      outlined
                      @click="navigateToHomeDashboard"
                    >
                      Back to dashboard
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </div>

            <v-toolbar flat color="grey lighten-4" v-show="!activeErrorMessage">
              <v-tabs v-model="tab" align-with-title color="black" show-arrows>
                <v-tabs-slider color="#ff002d"></v-tabs-slider>
                <v-tab
                  v-for="(configuration_type, i) in configurations"
                  :key="configuration_type.configuration_type"
                  :tag="configuration_type.configuration_type"
                  @click="handleTabChange()"
                >
                  {{ configurationTitles[i] }}
                </v-tab>
                <template v-if="help">
                  <v-spacer />
                  <v-tab>Help</v-tab>
                </template>
              </v-tabs>
            </v-toolbar>
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(configuration_type, i) in configurations"
                :key="i"
              >
                <ConfigurationForm
                  :key="configuration_type.configuration_type"
                  v-bind:fields="configuration_type.fields"
                  v-bind:title="configurationTitles[i]"
                  v-bind:type="configuration_type.configuration_type"
                >
                </ConfigurationForm>
              </v-tab-item>
              <v-tab-item v-if="help">
                <MarkdownContent
                  v-bind:source="help"
                  title="Help"
                ></MarkdownContent>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import ConfigurationForm from '../form/ConfigurationForm';
  import MarkdownContent from '../content/MarkdownContent';

  export default {
    name: 'FormPage',

    components: {
      ConfigurationForm,
      MarkdownContent,
    },

    data: () => ({
      appTitle: '',
      tab: 0,
      configurations: [],
      help: '',
      activeErrorMessage: null,
      errorMessages: {},
    }),

    computed: {
      configurationTitles: function () {
        return this.configurations.map((configuration) =>
          configuration.configuration_type
            .split('_')
            .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
            .join(' ')
        );
      },
    },

    methods: {
      navigateToHomeDashboard: function () {
        window.location.pathname = '/';
      },

      handleHashChange: function () {
        this.tab = this.selectTabByAnchor();
      },

      handleTabChange: async function () {
        // Ensure that the DOM is finished rendering before updating the URL.
        this.$nextTick(() => {
          const selectedTab = this.configurations[this.tab];

          if (!selectedTab.configuration_type) {
            return;
          }

          // Update the state.
          window.location.hash = `#${selectedTab.configuration_type}`;
        });
      },

      selectTabByAnchor: function () {
        const hash = window.location.hash;
        return this.configurations.findIndex(
          (tab) => '#' + tab.configuration_type === hash
        );
      },

      getSensorInformation: async function () {
        return axios.get(
          `${process.env.VUE_APP_API_HOST || ''}${
            process.env.VUE_APP_APP_PATH || ''
          }/api${this.$store.state.api.originPath}`
        );
      },

      updateAPIRoot: function (sensor_information) {
        if (
          typeof sensor_information.data.api_root === 'string' &&
          sensor_information.data.api_root !== ''
        ) {
          this.$store.commit('updateApiRoot', sensor_information.data.api_root);
        }
      },

      setAppTitle: function (sensor_information) {
        if (
          typeof sensor_information.data.title === 'string' &&
          sensor_information.data.title !== ''
        ) {
          this.appTitle = sensor_information.data.title;
        }
      },

      listConfigurations: function (sensor_information) {
        if (
          typeof sensor_information.data.configurations === 'object' &&
          typeof sensor_information.data.configurations.join === 'function'
        ) {
          this.configurations = sensor_information.data.configurations;
        }
      },

      addHelpData: function (sensor_information) {
        if (
          typeof sensor_information.data.help === 'string' &&
          sensor_information.data.help.length > 0
        ) {
          this.help = sensor_information.data.help;
        }
      },

      pushProductDetails: function (sensor_information) {
        if (
          typeof sensor_information.data.product_detail === 'object' &&
          typeof sensor_information.data.product_detail.url === 'string' &&
          typeof sensor_information.data.product_detail.label === 'string'
        ) {
          this.$store.commit(
            'updateProductDetail',
            sensor_information.data.product_detail
          );
        }
      },
    },
    created: async function () {
      this.errorMessages = {
        noConfigurationTemplatesError:
          'Unable to find a configuration template for this sensor type or protocol version.',
      };
      try {
        // Request sensor information.
        const response = await this.getSensorInformation();
        if (response.status !== 200) {
          this.activeErrorMessage = this.errorMessages.noConfigurationTemplatesError;
          return;
        }

        // Push the device's api root to the store.
        this.updateAPIRoot(response);

        // Set the device type and protocol version as title of the form page.
        this.setAppTitle(response);

        // Set the list of configurations.
        this.listConfigurations(response);

        // Add help data to the form page.
        this.addHelpData(response);

        // Push the sensor's product details to the store.
        this.pushProductDetails(response);

        // Automatically select the tab according to the anchor hash.
        this.tab = this.selectTabByAnchor();
      } catch (e) {
        this.activeErrorMessage = this.errorMessages.noConfigurationTemplatesError;
        return;
      }
    },

    mounted: function () {
      // Listen for hash changes and update the selected tab accordingly
      window.addEventListener('hashchange', this.handleHashChange);
    },

    beforeDestroy() {
      // Clean up the event listener when the component is destroyed
      window.removeEventListener('hashchange', this.handleHashChange);
    },
  };
</script>
