<template>
  <v-card 
    flat
  >
    <v-card-title
      class="red white--text"
      v-text="titleText"
    >
    </v-card-title>
    <v-card-text>
      <p
        v-if="response !== null && response.status === 500"
        class="py-6"
      >{{ serverError }}
      </p>
      <v-list three-line
        v-if="response !== null && response.status === 422 && unprocessableEntity.length > 0"
      >
        <template v-for="(unprocessableField, i) in unprocessableEntity">
          <v-list-item
            :key="`unprocessable-error-field-${i}`"
          >
          <v-list-item-content>
            <v-list-item-title v-text="unprocessableField.path"></v-list-item-title>
            <v-list-item-subtitle v-text="unprocessableField.type"></v-list-item-subtitle>
          </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="red"
        @click="close"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import dotProp from 'dot-prop';
import {pathToTitlePath} from '../../lib/genericHelpers';

export default ({
  name: 'ConfigurationResult',

  components: {
  },

  props: {
    response: Object
  },

  data: () => ({
    titleText: "An error occurred",
    apiErrorTypes: {
      value_error: {
        missing: "Please insert a value.",
        number: {
          not_ge: "Please confirm to the number boundaries.",
          not_le: "Please confirm to the number boundaries.",
        }
      },
      type_error: {
        integer: "Please enter a valid number.",
        float: "Please enter a valid number.",
        enum: "Please select one of the available options."
      }
    }
  }),

  computed: {
    serverError () {
      if (this.response.status !== 500) return '';

      return typeof (this.response.data || {}).error === 'string' ? this.response.data.error : 'Unknown error...';
    },

    unprocessableEntity () {
      if (typeof (this.response.data || {}).detail !== 'object' || this.response.data.detail === null) {
        return [];
      }

      return this.response.data.detail.map((field) => {
        return {
          path: pathToTitlePath(field.loc.join('.')),
          type: dotProp.get(this.apiErrorTypes, field.type, 'Please refer to the documentation.')
        }
      })
    }
  },

  methods: {
    close () {
      this.$emit('close-dialog');
    },
  }
})
</script>
