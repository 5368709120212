import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import dotProp from 'dot-prop'


Vue.config.productionTip = false
Vue.use(Vuex)

const baseUrl = new URL(window.location.href);

const transformObjectToArray = (parentObject, objectToArray) => {
  const arraySuffix = '[]';

  // Walk over the object keys to look for any array-type entries.
  Object.keys(objectToArray).forEach((key) => {
    // Plain objects can be copied.
    if (!key.endsWith(arraySuffix)) {
      // Copy the content.
      Object.assign(parentObject, { [key]: objectToArray[key] });

      // And recurse for objects only.
      if (typeof objectToArray[key] === 'object') {
        parentObject[key] = transformObjectToArray(parentObject[key], objectToArray[key])
      }
    }

    if (key.endsWith(arraySuffix)) {
      // Init the array.
      const cleanedKey = key.replace(/\[\]$/, '');
      Object.assign(parentObject, {
        [cleanedKey]: Object.keys(objectToArray[key]).map((listKey) => {
          return objectToArray[key][listKey];
        })
      });
    }
  });

  return parentObject;
};

const getFromUrlQuery = (queryParameter) => {
  const queryParameterData = baseUrl.searchParams.get(queryParameter);
  if (queryParameterData === null) return queryParameterData;
  return decodeURIComponent(queryParameterData);
};

const getObjectFromUrlQuery = (queryParameter) => {
  const queryParameterData = getFromUrlQuery(queryParameter);
  if (queryParameterData === null) return queryParameterData;

  let data;
  try {
    data = JSON.parse(queryParameterData);
  } catch(e) {
    data = null;
  }

  return data;
};

const store = new Vuex.Store({
  state: {
    api: {
      originPath: baseUrl.pathname,
      root: '/',
    },
    productDetail: {},
    prefilledFormData: getObjectFromUrlQuery('form-data') || {},
    formData: {},
    encodedData: {},
    errorFields: {},
    pushConfiguration: { // Push configuration result to remote URL.
      url: getFromUrlQuery('push-url'),
      meta: getObjectFromUrlQuery('push-meta')
    }
  },

  mutations: {
    updateApiRoot (state, payload) {
      Vue.set(state.api, 'root', payload);
    },

    updateProductDetail (state, payload) {
      Vue.set(state, 'productDetail', payload)
    },

    updateConfigurationValue (state, payload) {
      // Require a key-value object as input.
      if (typeof payload !== "object" || payload === null) return;

      // Update a configuration value by using the payload.
      // {<object path in dot notation>: <value>, ...}
      Object
        .keys(payload)
        .forEach((objectPath) => dotProp.set(state.formData, objectPath, payload[objectPath]));
    },
    updateConfigurationResult (state, payload) {
      Object.keys(payload).forEach((configuration_type) => {
        // Force a Vue set to enforce data update triggers to all object's children.
        Vue.set(state.encodedData, configuration_type, payload[configuration_type]);
      });
    },
    addErrorField (state, payload) {
      const [path, messages] = payload;

      const configuration_type = path.split(".")[0];
      if (typeof state.errorFields[configuration_type] === 'undefined') {
        state.errorFields[configuration_type] = {};
      }

      const updatedErrorFields = Object.assign({}, state.errorFields, {
        [configuration_type]: Object.assign({}, state.errorFields[configuration_type], {
          [path]: messages
        })
      });

      Vue.set(state, 'errorFields', updatedErrorFields);
    },
    deleteErrorField (state, path) {
      const configuration_type = path.split(".")[0];
      if (typeof state.errorFields[configuration_type] === 'undefined') {
        state.errorFields[configuration_type] = {};
      }

      Vue.delete(state.errorFields[configuration_type], path);
    }
  },

  getters: {
    getFormData: (state) => (configuration_type) => {
      // Name the object we're transforming.
      const configurationFormState = state.formData[configuration_type];

      // Initialize the output
      const output = transformObjectToArray({}, configurationFormState);

      return output;
    },
    getFieldDefaultValue: (state) => (path, fallback) => {
      const prefilledValue = dotProp.get(state.prefilledFormData, path);

      const output = typeof prefilledValue !== 'undefined' ? prefilledValue : fallback;

      return output;
    },
    getEncodedData: (state) => (configuration_type) => {
      return state.encodedData[configuration_type];
    },
    getFormErrors: (state) => (configuration_type) => {
      return state.errorFields[configuration_type] || {};
    },
    getFormIsLocked: (state) => (configuration_type) => {
      return Object.keys(state.errorFields[configuration_type] || {}).length > 0;
    },
    getPushConfiguration: (state) => () => {
      if (state.pushConfiguration.url === null) return false;
      return state.pushConfiguration;
    }
  }
})

new Vue({
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
